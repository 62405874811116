import React from 'react';
import Icon from '@ant-design/icons';

const HeraldSvg = () => (
  <svg viewBox="0 0 550 550" width="1em" height="1em" fill="currentColor">
    <g>
      <path d="M92.27,371.07l-46.5,39.23c-4.53,3.82-4.39,10.84,0.28,14.48l73.77,57.51c2,1.56,5.15-0.53,3.91-2.74
        c-21.85-39.22-37.65-66.48-26.26-105.82C98.25,371.02,94.42,369.26,92.27,371.07z"/>
      <path d="M457.73,372.19l46.5,39.23c4.53,3.82,4.39,10.84-0.28,14.48l-73.77,57.51c-2,1.56-5.15-0.53-3.91-2.74
        c21.85-39.22,37.65-66.48,26.26-105.82C451.75,372.14,455.58,370.37,457.73,372.19z"/>
      <path d="M485.55,244.91c-12.74-92.23-89.17-155.42-122.3-178.86c31.08,71.85,22.93,106.5,20.89,118.73
        c-4.08,20.38-32.61,18.85-36.69,5.1c-13.69-40.06-51.54-38.74-51.91-38.73h-0.07H255.8h-0.07c-0.37-0.01-38.22-1.33-51.91,38.73
        c-4.08,13.76-32.61,15.29-36.69-5.1c-2.04-12.23-10.19-46.88,20.89-118.73C154.9,89.49,78.46,152.68,65.72,244.91
        c-2.04,22.93,3.57,57.58,35.16,64.2c11.21,2.55,34.14,12.74,44.33,44.84c4.59,18.85,36.18,62.17,59.11,74.91
        s28.54,11.21,41.78,17.33c11.57,5.34,13.04,7.57,26.12,8.05c0.1,0.01,0.21,0.1,0.31,0.1c1.17,0,2.06-0.03,3.09-0.05
        c1.03,0.01,1.92,0.05,3.09,0.05c0.1,0,0.21-0.09,0.31-0.1c13.08-0.48,14.55-2.71,26.12-8.05c13.25-6.11,18.85-4.59,41.78-17.33
        s54.52-56.05,59.11-74.91c10.19-32.1,33.12-42.29,44.33-44.84C481.98,302.49,487.59,267.84,485.55,244.91z M275.9,394.72
        c-43.62,0-87.14-56.99-87.14-104.97s43.51-68.79,87.14-68.79c43.62,0,86.63,21.32,86.63,69.3S319.52,394.72,275.9,394.72z"/>
      <ellipse cx="275.64" cy="307.84" rx="24.71" ry="45.61"/>
    </g>
  </svg>
);

export default (props: any) => <Icon component={HeraldSvg} {...props} />;