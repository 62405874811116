import React from 'react';
import styled from 'styled-components';
import { Palette } from '../../css-in-js';
import { IconSize } from './icon';

interface ApgIconInterface {
  icon: string | React.FC;
  alt: string;
  size?: IconSize | number;
  color?: Palette;
}

interface FunctionalIconInterface {
  iconColor: Palette;
  size?: IconSize | number;
}

const FunctionalIcon = styled.div<FunctionalIconInterface>`
  stroke: ${({ iconColor }) => iconColor};
  width: ${({ size = IconSize.Xs }) => size}px;
  height: ${({ size = IconSize.Xs }) => size}px;
`;

const ApgIcon: React.FC<ApgIconInterface> = ({ icon, size, alt, color }: ApgIconInterface) => {
  let IconSource = icon;

  if (color && typeof IconSource === 'string') {
    const svg = new DOMParser().parseFromString(decodeURI(IconSource.replace(/data:image\/svg\+xml,/, '')), 'image/svg+xml')
      .documentElement;

    svg.querySelectorAll('*').forEach((el) => {
      /* istanbul ignore else */
      if (!el.hasAttribute('fill-rule')) {
        if (el.hasAttribute('stroke')) {
          el.setAttribute('stroke', encodeURIComponent(color));
        }
        if (el.hasAttribute('fill')) {
          if (el.getAttribute('fill') !== 'white' && el.getAttribute('fill') !== 'none') {
            el.setAttribute('fill', encodeURIComponent(color));
          }
        }
      }
    });
    IconSource = `data:image/svg+xml,${svg.outerHTML}`;
  }

  if (color && typeof IconSource === 'function') {
    return <FunctionalIcon as={IconSource} iconColor={color} size={size} />;
  }

  return typeof IconSource === 'string' ? (
    <img src={IconSource} alt={alt} style={{ width: `${size}px`, height: `${size}px` }} />
  ) : (
    <IconSource />
  );
};

export default ApgIcon;
