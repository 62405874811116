import React from 'react';
import Icon from '@ant-design/icons';

const DrakeSvg = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
    <g>
      <path d="M661.504 683.101c0 0-4.655 73.356 0 110.033 27.555 13.777 36.678-96.256 73.356-87.133s73.356 89.367 128.279 88.25c54.924-1.117 77.824-55.668 77.824-55.668s73.356 23.459 82.479 0c9.123-23.459-45.801-96.815-297.891-206.848-64.233-22.9-160.303-41.332-178.735 0 9.309 50.641 123.811 13.964 114.688 151.366z"></path>
      <path d="M310.924 736.908c21.597 17.687 98.49 110.033 138.705 131.817s194.746 36.678 216.529 10.24c21.783-26.438 8.937-110.778 89.367-100.724 32.023 6.889 29.789 28.858 58.461 36.864 28.672 7.82 59.578-11.543 66.467 13.591s-83.596 58.461-104.262 95.139c-20.666 36.678-51.572 100.724-171.846 95.139-120.273-5.772-183.203-34.444-226.769-90.484-43.567-56.227-51.572-103.145-77.824-114.502-26.438-11.543-73.356-12.66-53.807-58.461-0.372-0.186 13.219-61.44 64.977-18.618z"></path>
      <path d="M895.162 476.998c-4.655-146.711-160.303-224.535-311.668-247.436 0-142.057-36.678-215.412-36.678-215.412l-96.256 132.934c0 0-45.801-9.123-59.578 0s-32.023 100.724-54.924 100.724c-22.9 0-32.396 20.852-64.233 41.332-64.233 41.332-32.023 77.824-64.233 100.724-54.924 32.023-59.578 87.133-64.233 137.402-4.655 50.455-36.678 59.578-110.033 68.701-50.455 73.356-22.9 142.057 0 151.18s68.701 22.9 87.133 54.924c105.379 45.801 96.256 0 96.256-36.678s0-96.256 119.156-91.601 146.711-22.9 155.834-82.479c22.9-174.080 229.19-123.811 247.436-110.033s114.502 64.233 247.436 151.18c-8.937-91.415 0.186-63.86-91.415-155.462zM274.246 514.793c-16.012 9.123-37.795 10.24-41.332-24.017-3.351-34.444 22.9-45.801 22.9-45.801 29.789-24.017 70.935-5.772 69.818 16.012s-35.375 44.497-51.386 53.807z"></path>
    </g>
  </svg>
);

export default (props: any) => <Icon component={DrakeSvg} {...props} />;