import React from 'react';
import Icon from '@ant-design/icons';

const BaronSvg = () => (
  <svg viewBox="0 0 550 550" width="1em" height="1em" fill="currentColor">
    <g>
      <polygon points="212.02,528.38 151.6,438.83 155.96,395.14 18.36,261.91 18.36,366.75 92.02,406.72 91.06,459.98 	"/>
      <polygon points="337.98,528.38 398.4,438.83 394.04,395.14 531.64,261.91 531.64,366.75 457.98,406.72 458.94,459.98 	
        "/>
      <path d="M311.02,24.92c-8.54-3.71-19.64-3.26-19.64-3.26h-37c0,0-8.56-0.58-16.22,3.26
        C219.82,41.13,134.82,118.26,92.1,191.34c-1.11,1.9-1.66,4.04-1.66,6.24v43.93c0,4.08,2.02,7.83,5.43,10.06
        c17.91,11.68,74.57,50.08,85.57,75.14c2.14,30.61,28.63,83.48,68.59,128.01c0.94,1.04,2.74,0.21,2.72-1.19
        c-0.69-77.24-0.69-95.51-0.69-95.51s4.7-14.13,22.52-15.38c17.34,0.83,22.52,15.38,22.52,15.38s0,18.29-0.69,95.66
        c-0.01,1.35,1.72,2.16,2.63,1.15c40.01-44.55,66.55-97.48,68.68-128.12c11-25.06,67.67-63.46,85.57-75.14
        c3.41-2.23,5.43-5.98,5.43-10.06v-43.68c0-2.37-0.59-4.66-1.78-6.7C414.18,118.11,329.33,41.12,311.02,24.92z M212.87,247.26
        c-4.31,15.08-22.23,23.17-40.01,18.09c-17.78-5.09-28.7-21.43-24.39-36.51s22.23-23.18,40.01-18.09
        C206.26,215.84,217.18,232.19,212.87,247.26z M214.88,171.63c-18.5,0-33.49-12.71-33.49-28.39s14.99-28.39,33.49-28.39
        s33.49,12.71,33.49,28.39S233.38,171.63,214.88,171.63z M274.04,299.59c-18.5,0-33.49-12.71-33.49-28.39s14.99-28.39,33.49-28.39
        s33.49,12.71,33.49,28.39S292.53,299.59,274.04,299.59z M303.71,143.24c0-15.68,14.99-28.39,33.49-28.39s33.49,12.71,33.49,28.39
        s-14.99,28.39-33.49,28.39S303.71,158.92,303.71,143.24z M376.31,265.35c-17.78,5.09-35.69-3.01-40.01-18.09
        c-4.31-15.08,6.61-31.42,24.39-36.51c17.78-5.09,35.69,3.01,40.01,18.09C405.01,243.92,394.1,260.26,376.31,265.35z"/>
    </g>
  </svg>
);

export default (props: any) => <Icon component={BaronSvg} {...props} />;