import React, { FC } from 'react';
import { ProBuildStyled } from './ApgProBuildCard.styled';
import { ProBuildItem } from '../../domain/probuilds/probuilds';

export interface ApgProBuildCardInterface {
  build: ProBuildItem;
  onClick: (build: ProBuildItem) => void;
}

const ApgProBuildCard: FC<ApgProBuildCardInterface> = ({ build, onClick }): JSX.Element => {
  return (
    <ProBuildStyled.CardContainer onClick={() => onClick(build)} style={{ backgroundImage: `url(${build.champ.thumbUrl})` }}>
      <ProBuildStyled.ChampLane src={build.lane.image} alt={build.lane.name} />
      <ProBuildStyled.ChampName>{build.champ.name}</ProBuildStyled.ChampName>
      <ProBuildStyled.CreatorName>{build.creator.name}</ProBuildStyled.CreatorName>
    </ProBuildStyled.CardContainer>
  )
};

export default ApgProBuildCard;
