import React from 'react';
import Icon from '@ant-design/icons';

const KillsSvg = () => (
  <svg viewBox="0 0 550 550" width="1em" height="1em" fill="currentColor">
    <g>
      <path d="M63.9,29.08L39.09,53.9c-9.94,9.94-9.94,26.06,0,36l69.95,61.63l-43.52,43.52c-5.2,5.2-5.2,13.64,0,18.85
        l39.65,39.65c5.2,5.2,13.64,5.2,18.85,0L260.47,117.1c5.2-5.2,5.2-13.64,0-18.85L220.82,58.6c-5.2-5.2-13.64-5.2-18.85,0
        l-40.43,40.43L99.9,29.08C89.96,19.14,73.85,19.14,63.9,29.08z"/>
      <path d="M523.21,53.9l-24.82-24.82c-9.94-9.94-26.06-9.94-36,0l-61.63,69.95l-43.52-43.52c-5.2-5.2-13.64-5.2-18.85,0
        l-39.65,39.65c-5.2,5.2-5.2,13.64,0,18.85l38.79,38.79L19.34,409.54v118.83h118.83l256.19-318.74l40.82,40.82
        c5.2,5.2,13.64,5.2,18.85,0l39.65-39.65c5.2-5.2,5.2-13.64,0-18.85l-40.43-40.43l69.96-61.63
        C533.15,79.96,533.15,63.84,523.21,53.9z"/>
      <polygon points="400.53,309.51 530.66,409.54 530.66,528.38 411.83,528.38 311.14,403.1 	"/>
    </g>
  </svg>
);

export default (props: any) => <Icon component={KillsSvg} {...props} />;