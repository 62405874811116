import styled from "styled-components";
import { Palette, Spacing } from "../../css-in-js";
import { Font12, Font38, FontBoldItalic } from "../ApgTypography.styled";

const CardContainer = styled.div`
  align-items: center;
  background-color: ${Palette.Gray200};
  background-position: center center;
  background-size: cover;
  border-radius: 0 1rem 0 1rem;
  cursor: pointer;
  color: ${Palette.Gray100};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 20rem;
  overflow: hidden;
  padding: ${Spacing.Spacing2};
  position: relative;
  width: 100%;

  &:after {
    background: linear-gradient(10deg, ${Palette.Opacity50} 30%, transparent 50%);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

const ChampName = styled.div`
  ${FontBoldItalic}
  ${Font12}
  text-transform: uppercase;
  z-index: 2;
`

const CreatorName = styled.div`
  ${FontBoldItalic}
  ${Font38}
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: pre;
  width: 100%;
  z-index: 2;
`

const ChampLane = styled.img`
  height: 2rem;
  margin-bottom: .5rem;
  width: 2rem;
  z-index: 2;
`

export const ProBuildStyled = {
  CardContainer,
  ChampName,
  CreatorName,
  ChampLane
}